.profile_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
}
.title-container {
    display: flex;
    align-items: center;
}
.myprofile_title_icon {
    margin-right: 10px;
    font-size: 20px;
}
.profile_title {
    margin: 0;
    display: flex;
    align-items: center;
}
.profile_topbar {
    display: flex;
    align-items: center;
}
.profile_notification_icon,
.profile_language_selector,
.profile_user_info {
    margin-right: 10px;
}
.cancel {
    background-color: red;
    color: white;
}
.profile_user_info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    align-items: center;
}
.profile_icon {
    padding: 8px;
    border-radius: 50%;
    color: #0090d3;
    /* font-size: ; */
    background-color: #eee;
}
.profile_name {
    margin-left: 0.5rem;
}
.profile_language_selector {
    border: 1px solid #ccc;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    cursor: pointer;
}
.profile_notification_icon {
    position: relative;
    cursor: pointer;
}
.profile_notification_icon p {
    position: absolute;
    color: white;
    background-color: red;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    width: 1rem;
    text-align: center;
    border-radius: 50%;
    top: -10px;
    cursor: pointer;
    right: -5px;
}
