.modal {
    /* Default styles */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 650px;
    overflow: auto;
    animation: bounce 0.5s ease;
    border-radius: 10px;
    /* Media queries for responsive adjustments */
    @media (max-width: 768px) {
        width: 80%;
    }
}

.overlay {
    /* Default styles */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;

    /* Media queries for responsive adjustments */
    @media (max-width: 480px) {
        background-color: rgba(0, 0, 0, 0.8);
    }
}

.Container {
    display: grid;
    gap: 0;
}
.filter {
    display: flex;
}
.left_filter {
    background-color: #eaeaea;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 7rem;
    height: 22rem;
}
.left_filter button {
    border: none;
    color: #9a9292;
    background-color: inherit;
    font-size: 0.8rem;
    cursor: pointer;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    width: 100%;
    padding: 0.5rem;
}
.left_filter h3 {
    color: #5b5e63;
    text-transform: uppercase;
    font-size: 0.9rem;
    margin: 1rem;
}
.filter_input {
    padding: 2rem;
    position: relative;
}
.filter_input select {
    text-indent: 0.5rem;
}
.react-mulit-select {
    width: 20rem;
    border-radius: 10px;
    height: 2.5rem;
    outline: none;
}

.icon {
    position: absolute;
    left: 40px;
    top: 40px;
    color: #f2652e;
    font-size: 1.5rem;
}
.button {
    background-color: white !important;
    color: black !important;
}
.clear {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: #eaeaea;
}
.clear button:first-child {
    color: #f2652e;
    font-size: 1rem;
    border: none;
    font-family: inherit;

    padding: 0.8rem 1rem;
    cursor: pointer;
    font-size: 0.9rem;
}
.clear button:nth-child(2) {
    background-color: #f2652e;
    color: white;
    padding: 0.5rem 2rem;
    border-radius: 10px;
    height: fit-content;
    cursor: pointer;
    border: none;
}
@keyframes bounce {
    0% {
        transform: translate(-50%, -50%) scale(0.8);
        opacity: 0;
    }
    60% {
        transform: translate(-50%, -50%) scale(1.1);
    }
    80% {
        transform: translate(-50%, -50%) scale(0.95);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}
