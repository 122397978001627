.Container {
    display: grid;
    padding: 1rem;
}
.Container h3 {
    background-color: #1e87db;
    color: white;
    padding: 0.5rem;
    font-weight: 550;
    font-size: 1.3rem;
}
.Modal_filter {
    background-color: #1e87db;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    margin-left: 1rem;
    border-radius: 10px;
    cursor: pointer;
}
.Notification-container {
    display: grid;
    gap: 0.4rem;
    height: 100%;
}
.Notification {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 1rem 0.5rem;
    gap: 0.8rem;
    cursor: pointer;
}
.Notification:hover {
    background-color: #d6cfcf;
}

.Container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.Container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.Notification img {
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 50%;
}
.Notification h4 {
    font-size: 1.1rem;
    font-weight: 550;
}
.Notification h5 {
    font-size: 1rem;
    font-weight: 600;
}
.Notification-load {
    display: grid;
    align-items: center;
    justify-items: center;
}
.no-new {
    text-align: center;
    background-color: white;
    padding: 0.5rem;
}
