.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 15px;
    width: 500px;
    overflow: auto;
    border-radius: 5px;
    animation: bounce 0.5s ease;
    /* animation-fill-mode: forwards; */
    outline: none;
    /* opacity: 0; */
}

.approve-modal {
    width: calc(100vw - 285px);
    top: 10%;
    left: 240px;
    transform: initial;
}
.overlay {
    /* Default styles */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    /* Media queries for responsive adjustments */
}
.Approve_Modal_Container {
    display: grid;
    gap: 0.5rem;
    position: relative;
    width: 100%;
}
.Approve_Modal_Container h3 {
    text-align: center;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #ccc;
}
.Approve_Modal_Container p {
    font-size: 0.9rem;
}
.approve_data_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    gap: 1rem;
    /* gap: 4rem; */
}
.approve_data_container {
    height: 100%;
    max-height: 300px;
    overflow: auto;
    background-color: inherit;
}
.Approve_prevData label {
    color: #f2652e;
    font-size: 16px;
}
.approve_data_container {
    display: grid;
    gap: 0.5rem;
}

.col {
    border: 1px solid #ccc;
    display: grid;
    grid-template-columns: 1.5fr 1fr;

    align-items: center;
    gap: 0.3rem;
    word-break: break-all;
}
.col p {
    padding: 0.5rem;
}
.col strong {
    padding: 0.5rem;
    border-right: 1px solid #ccc;
}
.Request_approve_reject {
    justify-self: center;
    display: flex;
    gap: 0.5rem;
}
.Request_approve_reject button {
    color: white;
    padding: 0.2rem 1rem;
    border-radius: 10px;
    font-size: 0.9rem;
    border: none;
    cursor: pointer;
}
.Request_approve_reject button:first-child {
    background-color: green;
}
.btn_close {
    position: absolute;
    right: 0;
    cursor: pointer;
    font-size: 1rem;
    background-color: inherit;
    /* border-radius: 10px; */
    border: none;
}
.Request_approve_reject button:hover {
    background-color: darkgreen;
}
.Request_approve_reject button:nth-child(2) {
    background-color: red;
}
.Request_approve_reject button:nth-child(2):hover {
    background-color: darkred;
}
@media screen and (max-width: 768px) {
    .modal {
        width: 90%;
    }
}
@keyframes bounce {
    0% {
        /* transform: translate(-50%, -50%) scale(0.8); */
        opacity: 0;
    }
    60% {
        /* transform: translate(-50%, -50%) scale(1.1); */
    }
    100% {
        opacity: 1;
    }
}
