/* Login.css */

.login-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    /* background-color: #013366; */
    background-color: darkblue;
}

.login-input-container {
    position: relative;
    margin-bottom: 20px;
    cursor: pointer;
    display: grid;
    gap: 0.2rem;
}
.login-input-container span {
    color: orange;
}
.login-input-container span::first-letter {
    text-transform: uppercase;
}
.otpContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}
.logo {
    text-align: center;
}

.login_container2 {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    border-radius: 10px;
    margin: 20px;
}

.login_logo img {
    width: 100%;
    height: auto;
}

.login_header {
    color: #fff;
    text-align: center;
    padding: 20px;
}

.logo_text {
    color: #ffa500;
    margin-top: 10px;
    font-family: Cormorant;
    font-size: 36px;
    font-style: italic;
    font-weight: 400;
    line-height: 44px;
    letter-spacing: 0.01em;
    text-align: right;
    /* cursor: pointer; */
}

.login-form {
    display: flex;
    flex-direction: column;
}

.login-input-container {
    position: relative;
    margin-bottom: 20px;
    cursor: pointer;
    display: grid;
    gap: 0.2rem;
}

.login-input-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #008fd5;
    font-size: 20px;
}

.login-email,
.login-password,
#New_password,
#Confirm_password {
    width: 80%;
    padding: 15px 40px 15px 40px;
    border: 2px solid #ccc;
    border-radius: 10px;
    outline: none;
    font-size: 16px;
    /* cursor: pointer; */
}
.Input_wrapper {
    position: relative;
}
.login-input-container span {
    color: orange;
}
.login-input-container span::first-letter {
    text-transform: uppercase;
}
.password-toggle-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #ccc;
    font-size: 20px;
}

.input-error {
    border: 2px solid orange !important;
    /* animation: shake 0.5s ease-in-out; */
}
.input-error::first-letter {
    text-transform: uppercase;
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-5px);
    }
    50% {
        transform: translateX(5px);
    }
    75% {
        transform: translateX(-3px);
    }
    100% {
        transform: translateX(0);
    }
}

.login-remember-forgot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    color: #fff;
    cursor: pointer;
}

.login-remember-forgot a {
    color: #fff;
    text-decoration: none;
}

.login-submit {
    background-color: #ff6a00;
    color: white;
    padding: 15px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    align-items: center;
}
.login-submit:disabled,
.login-submit:disabled:hover {
    background-color: #ff6a00;
    cursor: initial;
}
.login-submit:hover {
    background-color: #e65c00;
}

.login-social-media {
    text-align: center;
    margin-top: 20px;
}

.login-social-media-img {
    margin: 10px;
}
.login-social-media-heading {
    text-align: center;
    font-size: 14px;
    color: #fff;
    padding: 10px;
    font-weight: 400;
}
.Back-to-login {
    color: #fff;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    padding: 10px;
    text-decoration: underline;
    cursor: pointer;
}
.otpinput {
    -moz-appearance: textfield;
    border: 2px solid #ccc;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
    outline: none;
    font-size: 16px;
    /* cursor: pointer;
    /* background-color: blue; */
    /* border: none; */
    color: black !important;
    border: 1px solid #ccc;
    /* border-radius: 16px; */
    color: #fff;
    font-size: 1rem;
    height: 3.5rem;
    outline: none;
    width: 3.5rem !important;
}

.error-msg,
.success-msg {
    color: red;
    margin: 0.5rem 0;
}
.success-msg {
    color: green;
}
/* Responsive Styles */
@media (max-width: 1024px) {
    .login-container {
        flex-direction: column;
        padding: 10px;
    }

    .login_container2 {
        margin: 10px;
    }

    .login-email,
    .login-password,
    #New_password,
    #Confirm_password {
        width: 85%;
        padding: 15px 30px 15px 35px;
    }

    .login-logo img {
        width: 500px;
    }
}

@media (max-width: 768px) {
    .login-container {
        flex-direction: column;
        padding: 10px;
    }
    .login_container2 {
        margin: 10px;
        padding: 10px;
    }

    .login-email,
    .login-password {
        width: 85%;
        padding: 15px 30px 15px 35px;
    }

    .login-logo img {
        width: 500px;
    }
}

@media (max-width: 425px) {
    .login-container {
        flex-direction: column;
        padding: 0px;
    }

    .login_container2 {
        padding: 0px;
        margin: 0px;
    }

    .login-email,
    .login-password,
    #New_password,
    #Confirm_password {
        width: 80%;
        padding: 15px 30px 15px 35px;
    }

    .login-logo img {
        width: 300px;
    }

    .logo_text {
        font-size: 16px;
        text-align: center;
    }
    .login-form {
        margin: 20px;
    }
}

@media (max-width: 320px) {
    .login_container2 {
        max-width: 400px;
        border-radius: 10px;
    }

    .login-logo img {
        width: 300px;
    }
}

@media (max-width: 1125px) {
    .logo_text {
        font-size: 28px;
        text-align: center;
    }
}
