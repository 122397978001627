.Table_layout {
    overflow: auto;
    display: grid;
    align-content: baseline;
    height: 70vh;
}

table {
    width: 100%;
    height: 100%;
    min-width: 1200px;
    height: 100%;
    border-collapse: collapse;
    overflow: scroll;
}

.sticky-header {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
}

table thead tr {
    border-radius: 10px;
    background: #f2f4f6;
}
table thead th {
    padding: 10px 5px;
    text-align: left;
    font-size: 14px;
    color: #1f2937;
    text-align: center;
    width: fit-content;
    word-wrap: break-word;
}

table tr td {
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    text-align: center;
    word-wrap: break-word;
    color: #374151;
    padding: 0.7rem 8px;
}
.pagination {
    display: flex;
    list-style-type: none;
    padding: 0;
    font-size: 12px;
}

.pagination li {
    margin: 0 5px;
}

.pagination a {
    text-decoration: none;
    color: #061739;
    padding: 4px 8px;
    border: 1px solid #061739;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.pagination a:hover {
    background-color: #061739;
    color: white;
}

.pagination .selected a {
    background-color: #061739;
    color: white;
    border-color: #061739;
}

.pagination .disabled a {
    color: #ccc;
    cursor: not-allowed;
    border-color: #ccc;
}

.pagination .previous a,
.pagination .next a {
    font-weight: bold;
}
.paginate-wrapper {
    justify-self: right;
    padding: 1rem 0;
}

.Status-codes {
    padding: 0.2rem 0.5rem;
    font-size: 12px;
    font-weight: 600;
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
}
.Status-codes::before {
    content: "•  ";
    margin-right: 0.3rem;
    font-size: 15px;
}
