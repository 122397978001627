.Filter-wrapper {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}
* {
    font-family: "Source Sans 3", sans-serif;
    margin: 0;
    padding: 0;
}
.Main_container {
    display: grid;
    gap: 1rem;
    border-radius: 10px;
    box-shadow: 0px 1px 2px 0px #38414a26;
    font-family: inherit;
    padding: 0.5rem;
    background-color: white;
}
.Filter-wrapper {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}
.Main_container h3 {
    color: #f2652e;
    padding-left: 10px;
    border-left: 3px solid #f2652e;
    font-size: 16px;
}

.filter {
    background-color: #fff;
    padding: 15px 25px 15px 25px;
    border: none;
    border-radius: 10px;
    display: flex;
    align-items: center;
    color: orange;
    gap: 0.5rem;
    font-size: 14px;
    margin-bottom: 2rem;
    font-weight: 600;
    /* color: black; */
    width: fit-content;
    cursor: pointer;
}
.request_Tabs {
    margin: 0.5rem;
    border-bottom: 1px solid #ccc;
    /* width: 100%; */
    /* max-width: 16rem; */
}
.request_Tabs button {
    padding: 0.5rem 1rem;
    border: none;
    cursor: pointer;
    background-color: inherit;
}
.request_Tabs .Active-tab {
    background-color: #4253f0;
    color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.filter-icon {
    font-size: 16px;
    font-weight: 600;
    color: #f2652e;
    margin-right: 5px;
}

.Action_btns {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
}
.Action_btns button {
    border: none;
    background-color: inherit;
    font-size: 1rem;
    cursor: pointer;
}

.form_wrapper_Modal {
    display: grid;
    gap: 1rem;
    align-items: center;
}
.form_wrapper_Modal header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
}
.form_wrapper_Modal header h3 {
    font-weight: 550;
}
.form_wrapper_Modal header button {
    border: none;
    background-color: inherit;
    font-size: 1.5rem;
}
.Add_form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: baseline;
    gap: 0.5rem;
}
.Form_Control,
.form_control_Search {
    display: grid;
    gap: 0.4rem;
}
.form_control_Search {
    margin: 0.5rem;
    position: relative;
    width: 100%;
}

.form_control_Search .search_icon {
    position: absolute;
    top: 8px;
    left: 5px;
}
.error {
    border: 1px solid red !important;
}
.Form_Control label {
    font-size: 0.9rem;
    font-weight: 600;
}
.Form_Control input,
.Form_Control select,
.form_control_Search input {
    width: 100%;
    text-indent: 0.5rem;
    border-radius: 10px;
    border: 1px solid #ccc;
    height: 2.5rem;
    outline: none;
}
.form_control_Search input {
    width: 100%;
    max-width: 20rem;
    text-indent: 1.8rem;
}
.Form_Control input:focus {
    border-color: lightblue;
}
.full-width {
    grid-column: 1/-1;
}
.add_form_btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
    /* border-top: 1px solid #ccc; */
}
.Form_Control span {
    font-size: 0.9rem;
    color: red;
}
.add_form_btns button {
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
}
.add_form_btns button[type="submit"] {
    background-color: #4253f0;
    color: white;
}

.Delete,
.Delete:hover {
    background-color: red !important;
}

/* Scrollbar Styles */
::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.status {
    padding: 0.2rem;
    border-radius: 5px;
    color: white;
    font-weight: bold;
}

.pending {
    background-color: orange;
    color: white; /* Adjust text color if necessary */
}

.approved {
    background-color: green;
    color: white; /* Adjust text color if necessary */
}

.rejected {
    background-color: red;
    color: white; /* Adjust text color if necessary */
}
.approve-view {
    border: none;
    background-color: inherit;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
.Action_btns_user {
    display: flex;
    align-items: center;
    justify-content: center;
}
.dropdown-heading-value {
    padding-left: 2rem;
}

.delete {
    font-size: 1rem;
    border: none;
    background-color: inherit;
    margin-left: 0.5rem;
}
.filter-form {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}
.filter-form-control {
    display: grid;
    gap: 0.5rem;
    /* width: 100%; */
}
.filter-form-control input,
.filter-form-control select {
    width: 12rem;
    text-indent: 0.8rem;
    height: 2.5rem;
    border-radius: 10px;
    outline: none;
    border: 1px solid #ccc;
    /* width: 100%; */
}
.filter-form-control label {
    font-size: 0.9rem;
}
.form_control_filter_button {
    align-self: center;
    padding: 0.5rem 1rem;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    background-color: #4253f0;
    color: white;
}

.Filters_wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin: 0.5rem 0;
    align-items: center;
}
.clear_filter_btn {
    color: white;
    background-color: red;
    border-radius: 2px;
    cursor: pointer;
    height: 2rem;
    border: none;
    padding: 0.5rem 1rem;
}
.Filters_wrapper .Filters {
    display: flex;
    align-items: center;
}
.Filters_wrapper .Filters h3,
.Filters_wrapper .Filters p {
    align-items: center;
    color: #fff;
    display: flex;
    font-size: small;
    justify-content: center;
    padding: 0.3rem;
    text-transform: uppercase;
    background-color: #444;
}
.Filters_wrapper .Filters p {
    background-color: #016997;
}
