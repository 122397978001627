.Action_btns {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Roles select {
    outline: none;
    border: 1px solid #ccc;
    font-size: 0.9rem;
    box-shadow: 0 1px 3px -2px #9098a9;
    text-align: center;
}
.edit-placeid {
    border: none;
    background-color: inherit;
    margin: 0.2rem 0.1rem;
    cursor: pointer;
}
.unlock:disabled {
    background-color: lightgray !important;
    cursor: not-allowed;
}
.unlock {
    color: white;
    background-color: green;
    cursor: pointer;
    border: none;
    padding: 0.2rem 0.5rem;
    border-radius: 10px;
}
.form-control {
    display: grid;
    gap: 0.5rem;
    width: 90%;
    justify-self: center;
    margin: 0.5rem 0;
}
.form-control input {
    border: 1px solid #ccc;
    height: 2.5rem;
    border-radius: 10px;
    text-indent: 10px;
    outline: none;
}
