.Container {
    display: grid;
    gap: 0.5rem;
}
.Filter {
    justify-self: right;
}
.Filter select {
    width: 8rem;
    text-indent: 0.5rem;
    border-radius: 10px;
    border: 1px solid #ccc;
    height: 2.5rem;
    outline: none;
    text-align: center;
}
.Sub_wrapper {
    display: grid;
    gap: 1rem;
    border-radius: 10px;
    box-shadow: 0px 1px 2px 0px #38414a26;
    font-family: inherit;
    padding: 0.5rem;
    background-color: white;
}
.Sub_wrapper h3 {
    color: #f2652e;
    padding-left: 10px;
    border-left: 3px solid #f2652e;
    font-size: 16px;
}
.Permission_wrapper {
    display: grid;
    gap: 0.5rem;
}
.Permission,
.Section_wrapper {
    display: grid;
    grid-template-columns: 300px 1fr;
    gap: 1rem;
    border: 1px solid #ccc;
}
.Section_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0rem;
    padding: 0.5rem;
    border-top: none;
    border-left: 0;
}
.Permission > h4 {
    padding: 0.5rem;
}
.user_permission {
    display: flex;
    gap: 0.5rem;
}
.Section_wrapper > h3 {
    font-size: 0.9rem;
}
